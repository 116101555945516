import dayjs from 'dayjs'
import * as React from 'react'
import { PublicInvestmentOfferBySlugQuery } from '/~/types/issuer-graphql'

interface PublicInvestmentOfferContext {
  publicInvestmentOfferBySlug: PublicInvestmentOfferBySlugQuery['publicInvestmentOfferBySlug']
  investmentOfferVisitor?: PublicInvestmentOfferBySlugQuery['investmentOfferVisitor']
  loading: boolean
}

export const context = React.createContext<PublicInvestmentOfferContext>({} as PublicInvestmentOfferContext)

export const usePublicInvestmentOfferData = () => {
  const data = React.useContext(context)

  return { ...data.publicInvestmentOfferBySlug, loading: data.loading }
}

export const usePublicInvestmentOfferFlags = () => {
  if (!context) {
    throw new Error('DO NOT USE THIS CONTEXT WITHOUT A PROVIDER WRAPPER FFS!')
  }

  const { publicInvestmentOfferBySlug, investmentOfferVisitor, loading } = React.useContext(context)

  const dates = publicInvestmentOfferBySlug?.dates
  const isInValidationPhase = !!publicInvestmentOfferBySlug?.isInValidationPhase
  const isReadonly = !!publicInvestmentOfferBySlug?.isReadonly

  const now = dayjs()
  const isOpen = isReadonly ? false : dates && dayjs(dates.open).isBefore(now) && dayjs(dates.close).isAfter(now)
  const isClosed = isReadonly ? false : dates && dayjs(dates.close).isBefore(now)
  const isValidating = isReadonly ? false : isInValidationPhase
  const isPreorder = isReadonly ? false : dates && dayjs(dates.open).isAfter(now)
  const isPreview = isReadonly ? false : !!publicInvestmentOfferBySlug?.isPreview // PREVIEW-HACK
  const singlePurposeVehicleEnabled = !!publicInvestmentOfferBySlug?.singlePurposeVehicleEnabled
  const hideDynamicValues =
    isReadonly || isPreview || (!isOpen && isPreorder && !investmentOfferVisitor?.approvedForPreorder)

  return {
    hideDynamicValues,
    isOpen,
    loading,
    isClosed,
    isValidating,
    isPreview, // PREVIEW-HACK
    isPreorder,
    userCanPreorder: investmentOfferVisitor?.approvedForPreorder,
    isFollowing: investmentOfferVisitor?.isFollowing,
    isReadonly,
    vpsEnabled: !!publicInvestmentOfferBySlug?.vpsEnabled,
    singlePurposeVehicleEnabled,
  }
}
